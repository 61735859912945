import { LoaderFunctionArgs, MetaFunction, json } from '@remix-run/node';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';

import { ErrorComponent } from '~/components/features/error/error-component.tsx';

import { env } from '~/utils/env/env.server.ts';
import { PublicEnv, publicEnvSchema } from '~/utils/env/public-env.tsx';
import i18next from '~/utils/i18next/i18next.server';
import { getPreferenceSession } from '~/utils/session/user-preference-session.server.ts';
import { setCurrentTheme } from '~/utils/theme/cookie.server';

import { findBrandingForOrganization } from '~/models/organizations.server.ts';
import { getCurrentOrganizationCookie } from '~/modules/context.server.ts';

import './inter.css';
import './tailwind.css';
import { cn } from './utils/css/css';
import { withSentry } from '@sentry/remix';
import crypto from 'crypto';
import process from 'node:process';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getToast } from 'remix-toast';
import { Toaster, toast } from 'sonner';

export const handle = {
  i18n: ['common'],
};
export const meta: MetaFunction = () => {
  return [{ title: 'Elterndashboard | Triargos' }];
};

function getWorkspace(request: Request) {
  const pathname = new URL(request.url).pathname;
  return pathname.split('/')[1];
}

function loadPublicEnv() {
  return publicEnvSchema.parse({
    IS_PRODUCTION: process.env.NODE_ENV === 'production',
    PUBLIC_SENTRY_DSN: env.PUBLIC_SENTRY_DSN,
  });
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  try {
    const currentOrganizationId = await getCurrentOrganizationCookie(request);
    const preferenceSession = await getPreferenceSession(request);
    const { toast, headers } = await getToast(request);
    headers.append('Set-Cookie', await setCurrentTheme(preferenceSession.getAppearance()));
    const locale = await i18next.getLocale(request);
    const branding = currentOrganizationId
      ? await findBrandingForOrganization(currentOrganizationId)
      : null;
    const brandingHash = crypto.createHash('md5').update(JSON.stringify(branding)).digest('hex');
    const workspace = getWorkspace(request);
    const publicEnv = loadPublicEnv();

    return json(
      {
        locale,
        preferences: preferenceSession.toObject(),
        workspace,
        toast,
        brandingHash,
        publicEnv,
      },
      {
        headers,
      },
    );
  } catch (e) {
    console.log('ERROR IN ROOT');
    throw e;
  }
};

function App() {
  const data = useLoaderData<typeof loader>();
  let { i18n } = useTranslation();
  useEffect(() => {
    if (data.toast) {
      switch (data.toast.type) {
        case 'success': {
          toast.success(data.toast.message);
          break;
        }
        case 'error': {
          toast.error(data.toast.message);
          break;
        }
        case 'info': {
          toast.info(data.toast.message);
          break;
        }
      }
    }
  }, [data.toast]);
  return (
    <html lang={data.locale} dir={i18n.dir()} className={cn('font-inter h-full')}>
      <head>
        <meta charSet='utf-8' />
        <meta
          content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
          id='viewport'
          name='viewport'
        />
        <Meta />
        <Links />
        <link rel={'stylesheet'} href={`/resources/theme.css?v=${data.brandingHash}`} />
      </head>
      <body className={cn(data.preferences.appearance, 'h-full')}>
        <Toaster theme={data.preferences.appearance} />
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <PublicEnv publicEnv={data.publicEnv} />
      </body>
    </html>
  );
}

export default withSentry(App);
export const ErrorBoundary = () => {
  return (
    <html>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <Meta />
        <Links />
      </head>
      <body>
        <ErrorComponent />
      </body>
    </html>
  );
};
